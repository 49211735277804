<template>
	<div ref="qrPanel" class="qr-panel">
		<client-only>
			<div class="row">
				<div class="columns">
					<div class="qr-container booking bookForm" @submit.prevent="initBookingEngine">
						<div class="qr-section">
							<label>
								{{ bookingForm.checkin }}
							</label>
							<date-picker
								v-model:value="state.arrival"
								:clearable="false"
								:disabled-date="disableArrivalDates"
								value-type="YYYY-MM-DD"
								format="DD-MM-YYYY"
								:placeholder="$t('formArrival')"
								class=""
								:lang="datepickerLang"
								@pick="pickArrival"
							>
								<template #icon-calendar> &nbsp; </template>
							</date-picker>
						</div>
						<div class="qr-section">
							<label>
								{{ bookingForm.checkout }}
							</label>
							<date-picker
								v-model:value="state.departure"
								:open="state.openDeparture"
								:disabled-date="disableDepartureDates"
								value-type="YYYY-MM-DD"
								format="DD-MM-YYYY"
								:placeholder="$t('formDeparture')"
								:default-value="state.pickedArrivalDate ?? new Date()"
								class=""
								:lang="datepickerLang"
								@open="state.openDeparture = true"
								@close="state.openDeparture = false"
							>
								<template #icon-calendar> &nbsp; </template>
							</date-picker>
						</div>
						<book-button
							class="qr-section button"
							:pax="2"
							:arrival="state.arrival"
							:departure="state.departure"
						>
							{{ bookingForm.availability }}
						</book-button>
					</div>
				</div>
			</div>
		</client-only>
	</div>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { locale } = useI18n();

defineProps({
	bookingForm: { type: Object, default: () => ({}) },
});

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickedArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickedArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style lang="scss">
.mx-calendar-content .cell.active {
	background-color: $primary-color;
}
</style>

<style lang="scss" scoped>
.qr-panel {
	position: absolute;
	left: 0;
	bottom: 50px;
	right: 0;
	z-index: 2;
	margin-bottom: -100px;
	opacity: 0;
	transition: all 0.8s ease-out;

	&.mounted {
		margin-bottom: 0;
		opacity: 1;
	}

	.columns:first-child {
		background: $body-background-color;
		padding: 0;
		border: 2px solid #fff;
	}

	.row {
		display: flex;
		flex-flow: nowrap row;
		align-items: center;
		justify-content: center;
	}
}

.qr-divider {
	width: 1px;
	height: 60px;
	margin: 15px 0 0;
	background: #696969;
}

.qr-container {
	display: flex;
}

.qr-section {
	float: left;
	padding: 14px 25px;
	min-width: 170px;
	max-width: 240px;
	position: relative;

	label {
		text-transform: uppercase;
		color: $primary-color;
		float: left;
		position: absolute;
		top: 22px;
		left: 25px;
		right: 0;
		font-size: 13px;
		letter-spacing: 1px;
	}

	:deep(input:not(.button)),
	:deep(select) {
		appearance: none;
		border: none;
		box-shadow: none;
		color: rgb(0 0 0 / 80%);
		font-size: 20px;
		font-family: $body-font-family;
		font-weight: 300;
		background: url('~/assets/images/qr-section-calendar-icon.png') no-repeat bottom 5px right 20px;
		background-size: 15px;
		background-color: none;
		position: relative;
		z-index: 1;
		cursor: pointer;
		padding: 30px 0 0;
		height: auto;

		&::placeholder {
			color: rgb(0 0 0 / 80%);
		}

		&:focus {
			outline: #fff;
		}
	}

	&.button {
		padding: 0 10px;
		border: none;
		min-height: 98px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 270px;
		font-size: 18px;
	}
}

.qr-panel .right a {
	margin: 40px 0 0;
	float: right;
}

@media (max-width: 1280px) {
	.qr-section {
		padding: 20px 10px;
		max-width: 260px;

		label {
			left: 10px;
		}
	}
}

@media (max-width: 1080px) {
	.qr-section {
		padding: 20px 8px;
		max-width: 225px;
	}
}

@media (max-width: 960px) {
	.qr-panel {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgb(245 239 237 / 60%);
		padding: 20px;
	}

	.qr-section,
	.qr-divider {
		display: none;
	}

	.qr-section.button {
		padding: 24px;
		border: none;
	}
}
</style>

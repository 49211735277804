<template>
	<transition name="page" mode="out-in">
		<header
			v-if="headingImage"
			ref="header"
			:class="{ subpage: !route.name.startsWith('index'), index: route.name.startsWith('index') }"
		>
			<div class="slider">
				<div class="slides">
					<picture>
						<source
							v-if="headingImage.imagePortrait && headingImage.imagePortraitWebp"
							:srcset="headingImage.imagePortraitWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source
							v-if="headingImage.imagePortrait"
							:srcset="headingImage.imagePortrait"
							media="(max-width: 500px)"
						/>
						<source v-if="headingImage.imageWebp" :srcset="headingImage.imageWebp" type="image/webp" />
						<source :srcset="headingImage.image" />
						<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
					</picture>
				</div>
			</div>

			<booking-form v-if="route.name.startsWith('index')" :booking-form="defaults[locale].bookingForm" />

			<div v-if="route.name.startsWith('index')" class="video-container">
				<iframe
					width="100%"
					height="100%"
					:src="
						'https://youtube.com/embed/' +
						defaults[locale].website.youtubeVideoID +
						'?version=3&enablejsapi=1&html5=1&hd=1&wmode=opaque&showinfo=0&playlist=' +
						defaults[locale].website.youtubeVideoID +
						'&autoplay=1&playsinline=1&rel=0&controls=0&mute=1&loop=1&iv_load_policy=3&modestbranding=0'
					"
					frameborder="0"
					loading="lazy"
					allow="autoplay"
				/>
			</div>
		</header>
	</transition>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();
const header = ref(null);

await fetchDefaults();

defineProps({
	headingImage: { type: Object, default: () => {} },
});
</script>

<style lang="scss" scoped>
header {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.slider,
.slider .slides {
	height: 100vh;
	position: relative;
}

.subpage .slider,
.subpage .slider .slides {
	height: 60vh;
}

.slides::after {
	content: '';
	position: absolute;
	inset: 0;
	z-index: 1;
	background: rgb(0 0 0 / 20%);
}

.slider-content {
	position: absolute;
	bottom: 220px;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 3;

	h2 {
		color: #fff;
		font-size: 80px;
	}
}

.slider-image {
	height: 100%;
	width: 100%;
	object-fit: cover;
	animation: fade-in 800ms ease-out;
}

/* Video header */
.video-header,
.index header {
	margin-top: -19vh;
}

.video-container {
	overflow: hidden;
	position: relative;
}

.video-container::before {
	content: '';
	position: absolute;
	inset: 0;
	z-index: 2;
}

.index header,
.video-container,
.video-container iframe {
	width: 100%;
	height: calc(((100vw * 9) / 16) + -13vh - 10px);
	z-index: 1;
	overflow: hidden;
}

.index .slider {
	display: none !important;
}

.video-container iframe {
	width: 108%;
	margin-left: -4%;
	height: calc(((100vw * 9) / 16) + 12vh);
	margin-top: -6vh;
}

.index .image-header,
.index .slider-content {
	display: none;
}

@media (max-width: 1100px) {
	.index .video-container {
		display: none !important;
	}

	.index .slider,
	.index .slider-content {
		display: block !important;
	}

	.video-header,
	.video-header iframe {
		height: auto;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-width: 960px) {
	.slider-content h2 {
		font-size: 50px;
	}
}

@media (max-width: 720px) {
	.slider-content h2 {
		font-size: 36px;
	}

	.slider .slides {
		transform: none !important;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	.slider,
	.slider .slides {
		height: 480px;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
	.slider,
	.slider .slides {
		height: 568px;
	}
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
	.slider,
	.slider .slides {
		height: 667px;
	}
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
	.slider,
	.slider .slides {
		height: 736px;
	}
}
</style>
